import React from "react";
import ArticleContent from "../../../ArticlePage/ArticleContent/ArticleContent";
import * as styles from './recipe-process.module.scss';
import { useDictionaryQuery, useLocale } from '../../../hooks'
const RecipeProcess = ({
    ingredientRequirements,
    method,
    triangleLeft
}) => {
    const { whatYouWillNeed, ingredients, methodRecipePage } = useDictionaryQuery()
    const locale = useLocale()
    return (
        <div>
            <div className={styles.processColumns}>
                <div className={styles.container}>
                    {ingredientRequirements && <div className={styles.ingredientsLeft}>
                        <h4 className={styles.subTitle}>{whatYouWillNeed}</h4>
                        <p className={styles.subsubTitle}>{ingredients}</p>
                        <ArticleContent content={ingredientRequirements} />
                    </div>}
                    <div className={styles.method}>
                        <h4 className={styles.subTitle}>{methodRecipePage}</h4>
                        <ArticleContent content={method} />
                    </div>
                </div>
            </div>
            {
                locale !== 'nl-NL'
                    ? <div className={`${triangleLeft ? styles.triangle_wrapper_left : styles.triangle_wrapper_right}`}>
                        <div className="triangle"></div>
                    </div>
                    : null
            }

        </div>
    )
}

export default RecipeProcess;
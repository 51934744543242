import React from "react"
import * as styles from "./banner.module.scss"

const Banner = ({ subtitle, heroTitle, color }) => {
  return (
    <div className={styles.banner_section_container} style={{ color: color }}>
      {subtitle && <p>{subtitle}</p>}
      <h2 className={`${styles.bannerTitle}`}>{heroTitle}</h2>
    </div>
  )
}

export default Banner

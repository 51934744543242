export const getYouTubeId = (youTubeUrl: string) => {
  if (!youTubeUrl) {
    return null;
  }

  let id = null;

  if (youTubeUrl.includes('youtu.be/')) {
    id = youTubeUrl.split('youtu.be/')[1];
  } else {
    let url = youTubeUrl.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
      id = url[2].split(/[^0-9a-z_\-]/i);
      id = id[0];
    }
    else {
      id = url;
    }
  }

  return id;
}

// extracted by mini-css-extract-plugin
export var bar = "purpose-video-module--bar--f3a7f";
export var featured_play_btn = "purpose-video-module--featured_play_btn--1c2b5";
export var featured_section_img = "purpose-video-module--featured_section_img--5f0db";
export var featured_video_container = "purpose-video-module--featured_video_container--fb806";
export var homeDesc = "purpose-video-module--home-desc--5e2af";
export var homeVideo = "purpose-video-module--home-video--06c04";
export var show = "purpose-video-module--show--f022e";
export var video = "purpose-video-module--video--32551";
export var videoContainer = "purpose-video-module--video-container--fa294";
export var videoDescription = "purpose-video-module--video-description--49b92";
export var videoTitle = "purpose-video-module--video-title--59c69";
export var video_wrapper = "purpose-video-module--video_wrapper--defa4";
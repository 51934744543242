import React from 'react'
import * as styles from './section-feature.module.scss';
import Banner from '../Banner/Banner';
import { RichText } from "../../Layout/RichText/RichText"
import Img from 'gatsby-image'
import { useLocalPath, useSiteQuery ,useSearchQuery} from '../../../hooks';
import { Link } from 'gatsby';
export const SectionFeatured = ({ data, pageSlug }) => {
    // Check if data.featuredItem exists and is not null
    if (!data.featuredItem) {
        return null; // or handle this case as per your requirement
    }

    switch (data.featuredItem.__typename) {
        case "ContentfulPageRecipe":
            return <SectionRecipe data={data} />
        case "ContentfulPageProducts":
            return <SectionProduct data={data} pageSlug={pageSlug} />
        case "ContentfulBackgroundImageSection":
            return <BackgroundImageSection data={data} />
        default:
            return null
    }
}


const SectionRecipe = ({ data }) => {
    const {
        title: sectionTitle,
        featuredItem: {
            heroImage,
            subTitle,
            slug,
            title
        }
    } = data
    return (
        <div>
            <div className={styles.sectionRecipe}>
                <div className={styles.featured_recipe_container}>
                    {sectionTitle && (
                        <>
                            <h2 className={`yellow_border spacer__01 ${styles.featuredRecipeTitle}`}>{sectionTitle}</h2>
                        </>
                    )}
                    <div className={styles.recipe_img_wrapper}>
                        <Img fluid={heroImage.fluid} alt={heroImage.title} className={styles.recipe_img} />
                        <p className={`${styles.textShadow} ${styles.textShadowP}`}>{subTitle}</p>
                        <Link to={useLocalPath(`/${slug}`)}>
                            <h3 className={styles.textShadow}>{title}</h3>
                        </Link>
                    </div>
                </div>
                <Banner heroTitle={title} subtitle={subTitle} color="white" />
            </div>
        </div>
    )
}

const SectionProduct = ({ data, pageSlug }) => {
    const {
        title: sectionTitle,
        featuredItem: {
            thumbnailImage,
            subTitle,
            slug,
            title,
            thumbnailDescription,
        },
        backgroundColor
    } = data;
    const { featureProductBackgroundImage } = useSiteQuery();
    const { shopNow } = useSearchQuery();

    // Define the class name based on backgroundColor
    let containerClassName = styles.featured_container; 
    if (backgroundColor === 'DarkGreen') {
        containerClassName = styles.featured_container_dark_green; 
    } else if (backgroundColor === 'Green') {
        containerClassName = styles.featured_container_green;
    }
    return (
        <div>
            <div className={styles.sectionProduct}>
                <div className={containerClassName}>
                    {sectionTitle && <h2 className={`yellow_border spacer__01 ${styles.featuredProductTitle}`}>{sectionTitle}</h2>}
                    <div className={styles.product_row}>
                        <div className={styles.imageContainer}>
                            <Link to={useLocalPath(`/${slug}`)}>
                                <Img fluid={thumbnailImage?.fluid} alt={thumbnailImage?.title} className={styles.productImg} />
                            </Link>
                        </div>
                        <div className={styles.product_desc}>
                            <h3>
                                <Link to={useLocalPath(`/${slug}`)}>
                                    {title}
                                </Link>
                            </h3>
                            {thumbnailDescription &&
                                <p>{thumbnailDescription?.thumbnailDescription}</p>
                            }
                            {backgroundColor === 'darkgreen' && // Render button only if backgroundColor is 'darkgreen'
                                <Link to={useLocalPath(`/${slug}`)}>
                                    <button>{data.featuredItem.cta[0].title}</button>
                                </Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SectionProduct;



const BackgroundImageSection = ({ data }) => {
    const {
        title: sectionTitle,
        featuredItem: {
            description,
            slug,
            imageSection,
            buttonUrl
        },
        backgroundColor
    } = data
    return (
        <div>
            <div className={styles.sectionProduct}>
                <div className={` ${backgroundColor ? styles.featured_container_green : styles.featured_container}`}>
                    {sectionTitle && <h2 className={`yellow_border spacer__01 ${styles.featuredProductTitle}`}>{sectionTitle}</h2>
                    }
                    <div className={styles.product_row}>
                        <div className={styles.backgroundContainer}>
                                        <Link to={useLocalPath(`/${slug}`)}>
                                <Img fluid={imageSection?.fluid} alt={imageSection?.title} className={styles.backgroundProduct} />
                            </Link>
                        </div>
                        <div className={styles.productDescription}>
                           <div className={styles.para}>
                        <Link to={useLocalPath(`/${slug}`)}>
                             <RichText content={description} />
                             </Link>
                            </div>    
                            {/* { buttonUrl && <Link to={useLocalPath(`/${slug}`)}>
                                    <button>{buttonUrl}</button>
                            </Link> }  */}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
// export default SectionFeatured
import React from 'react'
import { useUrl } from '../hooks'
import { useLocation } from '@reach/router'

const VideoSchema = ({
    fluid,
    videoSrcURL,
    title,
    description
}) => {
    const siteUrl = 'https://www.lipton.com';
    const { href } = useLocation()

    // const { logo } = useSiteQuery()
    const loc = useUrl()
        return (
            <div>
                <div style={{ display: "none" }}>
                <span itemProp='VideoObject' itemScope={true} itemType="http://schema.org/VideoObject">
                        {/* <span itemProp='brand'>Lipton</span> */}
                        <span itemProp='url'>{`${siteUrl}/${loc}/`}</span>
                        <span itemProp='mainEntityOfPage'>{href}</span>
                        {/* <meta itemProp='logo' content={logo.image[0].fluid.src} /> */}
                        <span itemProp='name'>{title}</span>
                        <span itemProp='description'>{description}</span>
                        <link itemProp="embedUrl" href={videoSrcURL} />
                        <meta itemProp="thumbnailUrl" content={fluid.src} />
                        <span itemProp='uploadDate'>2022-06-20</span>
                </span>
                </div>
            </div>
        )
        }

export default VideoSchema
import React from "react";
import { useLocale, useDictionaryQuery } from "../../../hooks";
import * as styles from './recipe-desc.module.scss';

const RecipeDesc = ({
    heading,
    description,
    longDescription,
    portions,
    standby,
    prepTime,
    serves,
    totalTime,
    freezingTime    
}) => {
    const { portionsRecipe, standbyRecipe, prepTimeRecipe, freezingTimeRecipe,  servesRecipe, totalTimeRecipe } = useDictionaryQuery()
    const locale = useLocale()
    return (
        
        <div className={styles.recipeDetail}>
            
            <div className={styles.description}>
                <div className={styles.recipeDescription}>
                    <div className={styles.topDescription}>
                       { heading ? <p className={styles.shortHeading}>{heading}</p> 
                       : ""
                      }
                      

                        {description && <h3 className={styles.descriptionDesc}>{description} </h3>}
                        {longDescription && <h3 className={styles.descriptionDesc}>{longDescription?.longDescription}</h3>}
                    </div>
                </div>
                <div className={`styles.nutritionContainer`}>
                    <div className={styles.nutrition}>
                        <div className={styles.nutritionValues}>
                        { portions &&
                        <div className={styles.values}>
                                <div className={styles.textSection}>
                                    <p className={styles.textTitle}>{portionsRecipe}</p>
                                    <p>{portions}</p>
                                </div>
                            </div>
                            }
                            {prepTime && 
                            <div className={styles.values}>
                                <div className={styles.textSection}>
                                    <p className={styles.textTitle}>{prepTimeRecipe}</p>
                                    <p>{prepTime}</p>
                                </div>
                            </div>}
                            {serves && 
                            <div className={styles.values}>
                                <div className={styles.textSection}>
                                    <p className={styles.textTitle}>{servesRecipe}</p>
                                    <p>{serves}</p>
                                </div>
                            </div>}
                            {standby && 
                            <div className={styles.values}>
                                <div className={styles.textSection}>
                                    <p className={styles.textTitle}>{standbyRecipe}</p>
                                    <p>{standby}</p>
                                </div>
                            </div>
                            }
                            {totalTime &&
                            <div className={styles.values}>
                                <div className={styles.textSection}>
                                    <p className={styles.textTitle}>{totalTimeRecipe}</p>
                                    <p>{totalTime}</p>
                                </div>
                            </div>}
                            { freezingTime &&
                                 <div className={styles.values}>
                                 <div className={styles.textSection}>
                                     <p className={styles.textTitle}>{freezingTimeRecipe}</p>
                                     <p>{freezingTime}</p>
                                 </div>
                                 </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default RecipeDesc;

import React from "react"
import { getYouTubeId } from "./Youtube"

const YoutubeVideo = ({ videoSrcURL, height, width, className, playVideo }) => {
  return (
    <iframe
      height={height}
      width={width}
      src={`https://www.youtube-nocookie.com/embed/${getYouTubeId(videoSrcURL)}?rel=0${
        playVideo ? "&autoplay=1&mute=1" : ""
      }`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className={className}
    ></iframe>
  )
}

export default YoutubeVideo
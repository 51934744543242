// extracted by mini-css-extract-plugin
export var backgroundContainer = "section-feature-module--background-container--931d9";
export var backgroundImage = "section-feature-module--background-image--321c5";
export var backgroundProduct = "section-feature-module--background-Product--120fe";
export var bar = "section-feature-module--bar--f636b";
export var featuredProductTitle = "section-feature-module--featured-product-title--61873";
export var featuredRecipeTitle = "section-feature-module--featured-recipe-title--6d274";
export var featured_container = "section-feature-module--featured_container--4a290";
export var featured_container_dark_green = "section-feature-module--featured_container_dark_green--a5740";
export var featured_container_green = "section-feature-module--featured_container_green--f6e0e";
export var featured_recipe_container = "section-feature-module--featured_recipe_container--5974c";
export var imageContainer = "section-feature-module--image-container--89d8b";
export var para = "section-feature-module--para--36592";
export var productBackgroundImage = "section-feature-module--product-background-image--b5a2c";
export var productContainer = "section-feature-module--product-container--9663d";
export var productDescription = "section-feature-module--product-description--9f4c5";
export var productImg = "section-feature-module--productImg--10fe7";
export var product_desc = "section-feature-module--product_desc--5c01d";
export var product_row = "section-feature-module--product_row--5bd2e";
export var recipe_img = "section-feature-module--recipe_img--f399f";
export var recipe_img_wrapper = "section-feature-module--recipe_img_wrapper--524ab";
export var sectionProduct = "section-feature-module--section-product--4065c";
export var sectionRecipe = "section-feature-module--section-recipe--94265";
export var subTitle = "section-feature-module--sub-title--6739a";
export var textShadow = "section-feature-module--text-shadow--844f8";
export var textShadowP = "section-feature-module--text-shadow-p--d1d8b";
// extracted by mini-css-extract-plugin
export var description = "recipe-desc-module--description--ea1d0";
export var descriptionDesc = "recipe-desc-module--description-desc--fca8d";
export var nutrition = "recipe-desc-module--nutrition--fe4ba";
export var nutritionLeft = "recipe-desc-module--nutrition-left--9f679";
export var nutritionRight = "recipe-desc-module--nutrition-right--5ca8f";
export var nutritionValues = "recipe-desc-module--nutrition-values--c84a3";
export var recipeDescription = "recipe-desc-module--recipe-description--4b11b";
export var recipeDetail = "recipe-desc-module--recipe-detail--9c779";
export var shortHeading = "recipe-desc-module--short-heading--60c81";
export var textSection = "recipe-desc-module--text-section--72c3f";
export var textTitle = "recipe-desc-module--text-title--17522";
export var topDescription = "recipe-desc-module--top-description--bad62";
export var values = "recipe-desc-module--values--6a02b";
import React, { useState, useEffect, useRef } from "react";
import * as styles from './purpose-video.module.scss';
import Img from 'gatsby-image';
import YoutubeVideo from "../YoutubeVideo";
import { Link } from "gatsby";
import { PlayIcon } from "../../../svgs/play";
import { useLocalPath } from "../../../hooks";
import VideoSchema from "../../VideoSchema";
//import ReactPlayer from 'react-player/lazy';

const PurposeVideo = ({
    title,
    description,
    longDescription,
    backgroundImage,
    backgroundVideo,
    home = false,
    buttonDetails = null,
    autoplay = false
}) => {
    const [playVideo, setPlayVideo] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        if (autoplay) {
            setPlayVideo(true);
        }
    }, [autoplay]);

    // const [isPlaying, setIsPlaying] = useState(false);
    // const [extraClass, setextraClass] = useState('');
  
    // const handleIconClick = () => {
    //   setIsPlaying(true); // Set play state to true
  
    //   // Add class upon click
    //   setextraClass('hide');
    // };

    return (
        <>

        <VideoSchema 
            // slug={getPathname(pageContext.node_locale, slug)} 
            fluid={backgroundImage?.fluid}
            videoSrcURL={backgroundVideo}
            title={description ? description : "Lipton Tea Video"}
            description={title ? title : "Lipton Tea Video"}
        />

        <div className={`${styles.featured_video_container} ${styles.videoContainer}`}>
            {home ? (
                <div className={styles.homeVideo}>
                    {description && <h2 className={`yellow_border`}><span>{description}</span></h2>}
                    {title && <p className={` ${styles.homeDesc}`}><span>{title}</span></p>}
                    {longDescription && <p className={` ${styles.homeDesc}`}><span>{longDescription.longDescription}</span></p>}
                </div>
            ) : (
                <>
                    {title && <p className={styles.videoTitle}><span>{title}</span></p>}
                    {description && <h2 className={styles.videoDescription}><span>{description}</span></h2>}
                </>
            )}
            <div className={styles.video_wrapper}>
                <Img
                    fluid={backgroundImage?.fluid}
                    alt={backgroundImage?.title}
                    className={styles.featured_section_img}
                    //className={`${styles.featured_section_img} ${extraClass}`}
                />
                {/* <div onClick={() => setPlayVideo(true)}>
                    <PlayIcon className={styles.featured_play_btn} />
                </div> */}
                <div  onClick={() => setPlayVideo(true)}>
                    <PlayIcon className={`${styles.featured_play_btn}`} />
                </div>
                {playVideo && backgroundVideo && (
                    <YoutubeVideo
                        width="100%"
                        height="100%"
                        className={`${styles.video} ${playVideo ? styles.show : ""}`}
                        videoSrcURL={backgroundVideo}
                        playVideo={playVideo}
                    />
                )}
                
               {/* <div onClick={handleIconClick}>
                    <PlayIcon className={`${styles.featured_play_btn} ${extraClass}`} />
                </div>
             {backgroundVideo && (
                                <ReactPlayer
                                url={backgroundVideo}
                                width='100%'
                                height='100%'
                                controls={true}
                                className={`${styles.video}`}
                                videoSrcURL={backgroundVideo}
                                muted={true}
                                playing={isPlaying}
                              />
                            )} */}

                
            </div>

            <div>
                {buttonDetails && (
                    <Link to={`${useLocalPath(buttonDetails.slug)}`}>
                        <button className={styles.btnInfo}>{buttonDetails.title}</button>
                    </Link>
                )}
            </div>
        </div>
        </>
    );
}

export default PurposeVideo;

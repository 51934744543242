import React from "react";
import { useLocale, useDictionaryQuery } from "../../../hooks";
import {useSearchQuery } from "../../../hooks/layout/useSearchQuery";
import * as styles from './nutritional-information.module.scss';

const NutritionalInformation = ({
    energyKcalRecipe,
    calories,
    cholestrol,
    totalCalories,
    totalCarbs,
    totalFat,
    transFat,
    sodium,
    dietaryCarbs,
    carbohydratesRecipe,
    caloriesFromFatRecipe,
    saturatedFatRecipe,
    sugarsRecipe,
    proteinRecipe,
    calciumRecipe,
    ironRecipe,
    vitaminCRecipe,
    potassiumRecipe,
    addedSugarRecipe,
    fiberRecipe,
    vitaminDRecipe,
    vitaminARecipe,
    saltGRecipe,
    polyunsaturatedFatValue,
    monounsaturatedFatValue,
    dietaryExchangesValue
}) => {
    const {
        nutritionalInformation,
        energyKcal,
        caloriesRecipe,
        totalFatRecipe,
        transFatRecipe,
        cholesterolRecipe,
        sodiumRecipe,
        totalCarbsRecipe,
        dietaryFiberRecipe,
        totalCaloriesRecipe,
        carbohydrates,
        caloriesFromFat,
        saturatedFat,
        sugars,
        protein,
        calcium,
        iron,
        vitaminC,
        vitaminD,
        vitaminA,
        saltG,
    } = useDictionaryQuery()
    const {
    potassium,
    addedSugar,
    fiber,
    polyunsaturatedFat,
    monounsaturatedFat,
    dietaryExchanges
    }
    = useSearchQuery()
    const locale = useLocale()
    return (
        <div>
            <div className={styles.values}>
                <div className={`${styles.valuesHeading} ${styles.hidden}`}>
                    <h4 className={`${styles.heading} ${styles.valuesHeading}`}>
                        {nutritionalInformation?.split(' ').map((word, index) => {
                            return (
                                <span key={index}>{word}</span>
                            )
                        })}
                    </h4>
                </div>
                <div className={styles.valuesHeading}>
                    <h4 className={`spacer__01 ${styles.heading} ${styles.valuesHeading}`}>
                        {nutritionalInformation?.split(' ').map((word, index) => {
                            return (
                                <span key={index}>{word}</span>
                            )
                        })}
                    </h4>
                </div>
                <div className={styles.infoContainer}>
                    
                    {energyKcalRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{energyKcal}</p>
                        <p className={`styles.informationValue`}>{energyKcalRecipe}</p>
                    </div>}

                    {calories &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{caloriesRecipe}</p>
                        <p className={`styles.informationValue`}>{calories}</p>
                    </div>}
                    {caloriesFromFatRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{caloriesFromFat}</p>
                        <p className={`styles.informationValue`}>{caloriesFromFatRecipe}</p>
                    </div> }
                    {totalFat &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{totalFatRecipe}</p>
                        <p className={`styles.informationValue`}>{totalFat}</p>
                    </div>}
                    {saturatedFatRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{saturatedFat}</p>
                        <p className={`styles.informationValue`}>{saturatedFatRecipe}</p>
                    </div> } 
                    {transFat &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{transFatRecipe}</p>
                        <p className={`styles.informationValue`}>{transFat}</p>
                    </div>}
                    {cholestrol &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{cholesterolRecipe}</p>
                        <p className={`styles.informationValue`}>{cholestrol}</p>
                    </div>}
                    {sodium &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{sodiumRecipe}</p>
                        <p className={`styles.informationValue`}>{sodium}</p>
                    </div>}
                    {carbohydratesRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{carbohydrates}</p>
                        <p className={`styles.informationValue`}>{carbohydratesRecipe}</p>
                    </div> }     
                    {
                    totalCarbs &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{totalCarbsRecipe}</p>
                        <p className={`styles.informationValue`}>{totalCarbs}</p>
                    </div>}
                    {dietaryCarbs &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{dietaryFiberRecipe}</p>
                        <p className={`styles.informationValue`}>{dietaryCarbs}</p>
                    </div>}
                    
                    {sugarsRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{sugars}</p>
                        <p className={`styles.informationValue`}>{sugarsRecipe}</p>
                    </div> }
                    {proteinRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{protein}</p>
                        <p className={`styles.informationValue`}>{proteinRecipe}</p>
                    </div> }
                    {calciumRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{calcium}</p>
                        <p className={`styles.informationValue`}>{calciumRecipe}</p>
                    </div> }
                    {ironRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{iron}</p>
                        <p className={`styles.informationValue`}>{ironRecipe}</p>
                    </div> }
                    {vitaminCRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{vitaminC}</p>
                        <p className={`styles.informationValue`}>{vitaminCRecipe}</p>
                    </div> }
                    {potassiumRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{potassium}</p>
                        <p className={`styles.informationValue`}>{potassiumRecipe}</p>
                    </div> }
                    {addedSugarRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{addedSugar}</p>
                        <p className={`styles.informationValue`}>{addedSugarRecipe}</p>
                    </div> }
                    {fiberRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{fiber}</p>
                        <p className={`styles.informationValue`}>{fiberRecipe}</p>
                    </div> }
                    {vitaminDRecipe &&
                      <div className={`${styles.information}`}>
                      <p className={styles.informationTitle}>{vitaminD}</p>
                      <p className={`styles.informationValue`}>{vitaminDRecipe}</p>
                  </div> }
                    {vitaminARecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{vitaminA}</p>
                        <p className={`styles.informationValue`}>{vitaminARecipe}</p>
                    </div> }

                    {saltGRecipe &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{saltG}</p>
                        <p className={`styles.informationValue`}>{saltGRecipe}</p>
                    </div> }

                    {polyunsaturatedFatValue &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{polyunsaturatedFat}</p>
                        <p className={`styles.informationValue`}>{polyunsaturatedFatValue}</p>
                    </div> }

                    {monounsaturatedFatValue &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{monounsaturatedFat}</p>
                        <p className={`styles.informationValue`}>{monounsaturatedFatValue}</p>
                    </div> }

                    {dietaryExchangesValue &&
                    <div className={`${styles.information}`}>
                        <p className={styles.informationTitle}>{dietaryExchanges}</p>
                        <p className={`styles.informationValue`}>{dietaryExchangesValue}</p>
                    </div> }
                </div>
            </div>
        </div>
    )
}

export default NutritionalInformation;
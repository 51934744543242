import { graphql } from "gatsby"
import React, { useState } from "react"
import loadable from "@loadable/component"
import RecipeProcess from "../components/Recipes/RecipeProcess/RecipeProcess"
import GenericHero from "../components/Shared/Generic/Hero/GenericHero"
import RecipeDesc from "../components/Recipes/RecipeDesc/RecipeDesc"
import RelatedRecipes from "../components/Recipes/RelatedRecipes/RelatedRecipes"
const ArticleRelatedArticles = loadable(() => import("../ArticlePage/ArticleRelatedArticles/ArticleRelatedArtciles"))
import NutritionalInformation from "../components/Recipes/NutritionalInformation/NutritionalInformation"
import RelatedArticles from "../components/Shared/RelatedArticles/RelatedArticles"
import Seo from "../components/seo"
import { useLocale, useDictionaryQuery } from "../hooks"
import Schema from "../components/schema"
const RecipesRelatedRecipes = loadable(() => import("../components/Recipes/RelatedRecipes/RecipesRelatedRecipes"))
import PurposeVideo from "../components/Shared/PurposeVideo/PurposeVideo"
import { SectionFeatured } from "../components/Shared/SectionFeatured/SectionFeatured"
import { getPathname } from "../utils/functions"

const Recipe = ({
    location,
    pageContext,
    data: {
        contentfulPageRecipe: {
            noNutritionalSection,
            energyKcal,
            calories,
            cholestrol,
            heading,
            description,
            longDescription,
            seoTitle,
            seoDescription,
            heroImage,
            ingredients,
            method,
            prepTime,
            recipeIcon,
            relatedRecipes,
            relatedArticles,
            serves,
            sodium,
            slug,
            title,
            dietaryCarbs,
            totalCalories,
            totalCarbs,
            totalFat,
            totalTime,
            freezingTime,
            transFat,
            portions,
            standby,
            carbohydrates,
            caloriesFromFat,
            saturatedFat,
            sugars,
            protein,
            calcium,
            iron,
            vitaminC,
            vitaminD,
            addedSugar,
            fiber,
            potassium,
            vitaminA,
            saltG,
            carouselRelatedRecipe,
            featuredsection,
            moreNutritionalInformation
        },
        allContentfulPageRecipe: { nodes: allLinks },
    },
}) => {
    const { recipeCategoryTitle } = useDictionaryQuery()
    const lang = useLocale()

    if(moreNutritionalInformation){
      var [obj] = moreNutritionalInformation.map((el)=>el)
    }
    
    return (
        <>
            <Seo
                lang={lang}
                title={seoTitle}
                description={seoDescription.seoDescription}
                productImage={heroImage}
                hrefLangs={allLinks}
            />
            {/* <Schema slug={getPathname(pageContext.node_locale,slug)}/> */}
            <Schema 
              slug={getPathname(pageContext.node_locale,slug)}
              type={"Recipe"}
              name={title}
              description={description}
              recipeImg={heroImage && heroImage.fluid.src}
            />
            <div>
                <GenericHero
                    subtitle={recipeCategoryTitle}
                    fluid={heroImage.fluid}
                    title={title}
                    fluidIcon={recipeIcon?.fluid}
                    firstChild={true}
                />

                <RecipeDesc
                    heading={heading}
                    description={description}
                    longDescription={longDescription}
                    prepTime={prepTime}
                    serves={serves}
                    totalTime={totalTime}
                    portions={portions}
                    standby={standby}
                    freezingTime={freezingTime}
                />

                <RecipeProcess
                    ingredientRequirements={ingredients}
                    method={method}
                    triangleLeft={false}
                />
                {!noNutritionalSection && <NutritionalInformation
                    energyKcalRecipe={energyKcal}
                    calories={calories}
                    cholestrol={cholestrol}
                    totalCalories={totalCalories}
                    totalCarbs={totalCarbs}
                    totalFat={totalFat}
                    transFat={transFat}
                    sodium={sodium}
                    dietaryCarbs={dietaryCarbs}
                    carbohydratesRecipe={carbohydrates}
                    caloriesFromFatRecipe={caloriesFromFat}
                    saturatedFatRecipe={saturatedFat}
                    sugarsRecipe={sugars}
                    proteinRecipe={protein}
                    calciumRecipe={calcium}
                    ironRecipe={iron}
                    vitaminCRecipe={vitaminC}
                    vitaminARecipe={vitaminA}
                    vitaminDRecipe={vitaminD}
                    addedSugarRecipe={addedSugar}
                    fiberRecipe={fiber}
                    potassiumRecipe={potassium}
                    saltGRecipe={saltG}
                    polyunsaturatedFatValue = {obj && obj.polyunsaturatedFatValue}
                    monounsaturatedFatValue = {obj && obj.monounsaturatedFatValue}
                    dietaryExchangesValue = {obj && obj.dietaryExchangesValue}                    
                />}
                {featuredsection &&
                    featuredsection.map((data: any, index: number) => {
                        if (data.__typename === "ContentfulComponentYoutubeMedia") {
                            return (
                                <PurposeVideo
                                    description={data.heading}
                                    title={data.genericDesc}
                                    backgroundVideo={data.youtubeVideo}
                                    backgroundImage={data.bannerImage}
                                    home={true}
                                    key={index}
                                />
                            )
                        } else if (data.__typename === "ContentfulComponentFeatured") {
                            return (
                                <div key={index}>
                                    <SectionFeatured data={data} />
                                </div>
                            )
                        }
                    })
                }

                {
                    relatedRecipes
                        ? <RelatedRecipes recipes={relatedRecipes} icon={recipeIcon} />
                        : null
                }

                {/* {
                    lang !== "es-PE" && lang !== "en-PK" && (lang !== "fr-CA") && (lang !== "en-CA") && (lang !== "tr-TR")  ? <RelatedRecipes recipes={relatedRecipes} icon={recipeIcon} /> : ""
                } */}

                {
                    relatedArticles
                        ? <ArticleRelatedArticles articles={relatedArticles} />
                        : null
                }

                {
                    carouselRelatedRecipe
                        ? <RecipesRelatedRecipes
                            articles={carouselRelatedRecipe}
                            recipeIcon={recipeIcon}
                        />
                        : null

                }
            </div>
        </>
    )
}

export const RecipeQuery = graphql`
  query recipePageQuery($id: String!, $contentful_id: String!) {
    contentfulPageRecipe(id: { eq: $id }) {
      noNutritionalSection
      slug
      title
      seoTitle
      seoDescription {
        seoDescription
      }
      heroImage {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      recipeIcon {
        title
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      description
      longDescription {
        longDescription
      }
      prepTime
      serves
      totalTime
      portions
      standby
      ingredients {
        raw
      }
      method {
        raw
      }
      energyKcal
      calories
      totalFat
      totalCarbs
      totalCalories
      dietaryCarbs
      transFat
      sodium
      cholestrol
      carbohydrates
      saturatedFat
      sugars
      protein
      calcium
      iron
      vitaminC
      vitaminD
      vitaminA
      saltG
      potassium
      addedSugar
      fiber
      relatedRecipes {
        title
        slug
        heroImage {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      featuredsection {
        __typename
        ... on ContentfulComponentFeatured {
          title
          featuredItem {
           __typename
            ... on ContentfulPageProducts {
              title
              slug
              thumbnailImage {
                title
                fluid {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        ... on ContentfulComponentYoutubeMedia {
          youtubeVideo
          bannerImage {
            fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
    }
      relatedArticles {
        title
        slug
        thumbnailCategoryTitle
        thumbnailImage {
          title
          fluid(quality: 75) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        articlePageIcon {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      carouselRelatedRecipe {
        ... on ContentfulPageRecipe {
          title
          slug
          heroImage {
            title
            fluid(quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          subTitle
          recipeIcon {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      moreNutritionalInformation {
        ... on ContentfulComponentRecipesNutritionalInformation {
          polyunsaturatedFatValue
          monounsaturatedFatValue
          dietaryExchangesValue
        }
      }
    }
    allContentfulPageRecipe(
      filter: {
        contentful_id: { eq: $contentful_id }
        id: { ne: $id }
        slug: { ne: null }
      }
    ) {
      nodes {
        slug
        node_locale
      }
    }
  }
`

export default Recipe
